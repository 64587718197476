/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
// import { useState, useEffect } from 'react';
// import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
// const data
import vedaInfo from 'vedaInfo';

// exta css
const style = {
    common: {
        zIndex: '1000',
        position: 'fixed',
        left: '10px',
        padding: '14px 18px',
        borderRadius: '50%',
        border: 'none',
        animation: 'bot-to-top 2s ease-out'
    },
    button2: {
        bottom: '70px',
        backgroundColor: '#25D366',
        color: 'white'
    },
    icon2: {
        color: '#fff',
        fontSize: '28px'
    },
    button3: {
        zIndex: '1000',
        position: 'fixed',
        left: '0px',
        padding: '10px 5px',
        borderRadius: '5px',
        border: 'none',
        top: '250px',
        backgroundColor: 'var(--errorMain)',
        color: 'white'
    },
    btnTitle: {
        color: '#fff',
        fontSize: '16px',
        writingMode: 'vertical-lr',
        transform: 'rotate(-180deg)'
        // textOrientation: 'upright'
    }
};

function index() {
    // const isMobile = useMediaQuery({ maxWidth: 767 });
    // const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);

    // const toggleSubMenu = () => {
    //     setIsSubMenuOpen(!isSubMenuOpen);
    // };

    // const whatsappUs = () => {
    //     console.log('whatsappUs');
    // };
    // const enquiryUs = () => {
    //     console.log('enquiryUs');
    // };
    return (
        <>
            {/* <button style={{ ...style.common, ...style.button1 }} onClick={callUs}>
                <i className="fas fa-phone-alt" style={style.icon1}></i>
            </button> */}
            <a
                href={`https://api.whatsapp.com/send?phone=${vedaInfo.contactInfo.whatsappNo}&text&type=phone_number&app_absent=0`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button style={{ ...style.common, ...style.button2 }}>
                    <i className="fab fa-whatsapp" style={style.icon2}></i>
                </button>
            </a>

            <Link to="contact-us" style={style.button3}>
                <div style={style.btnTitle}>Enquiry Us</div>
            </Link>
        </>
    );
}

export default index;
