const vedaInfo = {
    instituteName: 'Veda Career Institute',
    Address: '5, Janki Nagar NX, Oppo. Sky Height , Novlakha Indore ( M.P.)',
    socialMediaLinks: {
        facebook: 'https://www.facebook.com/vedacareerinstitute?mibextid=cejktS',
        instagram: 'https://instagram.com/vedacareer?igshid=YTQwZjQ0NmI0OA==',
        telegram: 'https://t.me/vedacareerinstituteindore',
        linkedIn: '#',
        twitter: '#',
        pinterest: '#',
        youtube: 'https://www.youtube.com/channel/UCAxI8XMd-HTQ5HF6Q8piM4g'
    },
    contactInfo: {
        contactNo1: '+91 8889566866',
        contactNo2: '+91 9981235898',
        whatsappNo: '918889566866', //don't include country code
        emailId1: 'vedacareeri@gmail.com',
        emailId2: ''
    }
};

export default vedaInfo;
