/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import footerBg from 'assets/images/counter-bg.webp';
import axios from 'axios';

// project others
import envVariables from 'environment.js';
// const data
import vedaInfo from 'vedaInfo';

const ourInfo = [
    {
        title: 'About Us',
        url: 'about-us'
    },
    {
        title: 'Contact Us',
        url: 'contact-us'
    },
    {
        title: 'Privacy Policy',
        url: 'privacy-policy'
    },
    {
        title: 'Terms of Use',
        url: 'terms-of-use'
    },
    {
        title: 'Return-Policy',
        url: 'return-policy'
    }
];
const latestCourses = [
    {
        title: 'Banking',
        url: 'courses'
    },
    {
        title: 'SSC',
        url: 'courses'
    },
    {
        title: 'PSC',
        url: 'courses'
    },
    {
        title: 'Railway',
        url: 'courses'
    },
    {
        title: 'Agriculter',
        url: 'courses'
    }
];
const socialLinks = [
    {
        title: 'Youtube',
        icon: 'fab fa-youtube',
        url: vedaInfo.socialMediaLinks.youtube
    },
    {
        title: 'Facebook',
        icon: 'fab fa-facebook',
        url: vedaInfo.socialMediaLinks.facebook
    },
    {
        title: 'Instagram',
        icon: 'fab fa-instagram',
        url: vedaInfo.socialMediaLinks.instagram
    },
    {
        title: 'Telegram',
        icon: 'fab fa-telegram-plane',
        url: vedaInfo.socialMediaLinks.telegram
    }
];
function Index() {
    const [latestCourses, setLatestCourses] = useState([]);
    async function footerCourseList() {
        await axios
            .get(`${envVariables.baseURL}api/exam-categories`)
            .then((res) => {
                setLatestCourses(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        footerCourseList();
    }, []);
    return (
        <>
            <section className="footer-area bg_cover" style={{ backgroundImage: ` url(${footerBg})` }}>
                <div className="footer-widget">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-link mt-45">
                                    <h4 className="footer-title">Our Information</h4>
                                    <ul className="link-list">
                                        {ourInfo.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={item.url}>{item.title}</Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-link mt-45">
                                    <h4 className="footer-title">Latest Courses</h4>
                                    <ul className="link-list">
                                        {latestCourses.slice(0, 5).map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to="./courses">{item.category_name}</Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-link mt-45">
                                    <h4 className="footer-title">Social Links</h4>
                                    <ul className="link-list">
                                        {socialLinks.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <a href={item.url} target="_blank">
                                                        <i className={item.icon} style={{ fontSize: '1rem', marginRight: '10px' }}></i>
                                                        <span>{item.title}</span>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-link mt-45">
                                    <h4 className="footer-title">Contact Info</h4>
                                    <ul className="link-list">
                                        <li>
                                            <p>{vedaInfo.Address}</p>
                                        </li>
                                        <li>
                                            <p>
                                                <a href={`tel:${vedaInfo.contactInfo.contactNo1}`}>{vedaInfo.contactInfo.contactNo1}</a>
                                            </p>
                                            <p>
                                                <a href={`tel:${vedaInfo.contactInfo.contactNo2}`}>{vedaInfo.contactInfo.contactNo2}</a>
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <a href={`mailto:${vedaInfo.contactInfo.emailId1}`}>{vedaInfo.contactInfo.emailId1}</a>
                                            </p>
                                            <p>
                                                <a href={`mailto:${vedaInfo.contactInfo.emailId2}`}>{vedaInfo.contactInfo.emailId2}</a>
                                            </p>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=co.arya.gofbp&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                                target="_blank"
                                            >
                                                <img
                                                    alt="Get it on Google Play"
                                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">
                        <div className="copyright text-center">
                            <p>
                                Copyright &copy; 2023{' '}
                                <span>
                                    <a href="#"> Veda Career Institute</a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index;
