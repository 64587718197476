/* eslint-disable no-unused-vars */
import React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// bootstrap
import { Button, Modal, Container } from 'react-bootstrap';

import axios from 'axios';

// project others
import envVariables from 'environment.js';

// project imports
import logo from 'assets/images/logo.png';
import InfoMsg from 'common/error_not_found/index';

function Index({ ...props }) {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [modalShow, setModalShow] = useState(true);
    const [posterUrl, setPosterUrl] = useState(logo);
    const [isLoading, setLoading] = useState(true);
    const [navLink, setNavLink] = useState('#');
    async function fetchPoster() {
        setLoading(true);
        await axios
            .get(`${envVariables.baseURL}api/popup`)
            .then((res) => {
                setPosterUrl(res.data.data.image_url);
                setNavLink(res.data.data.url);
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    }
    useEffect(() => {
        fetchPoster();
    }, []);

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Container fluid style={{ display: 'flex', justifyContent: 'center' }}>
                        {isLoading ? (
                            <InfoMsg msgno="2" />
                        ) : isMobile ? (
                            <a href={navLink} target="_blank">
                                <img src={posterUrl} width={props.width} height={props.height} alt="Logo" style={{ objectFit: 'cover' }} />
                            </a>
                        ) : (
                            <a href={navLink} target="_blank">
                                <img src={posterUrl} width={props.width} height={props.height} alt="Logo" style={{ objectFit: 'cover' }} />
                            </a>
                        )}
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
    return <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />;
}

export default Index;
