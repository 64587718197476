/* eslint-disable no-unused-vars */
import React from 'react';
// thrid party
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Loader from 'loader';
// componnets routing
// const Layout = lazy(() => import('layout/index.js'));
import Layout from 'layout/index';
const Home = lazy(() => import('pages/home/index'));
// Start About -> subpages
const AboutUs = lazy(() => import('pages/about/index'));
// const AboutInstitute = lazy(() => import('pages/about/about-institute/index'));
// const ExperTeam = lazy(() => import('pages/about/expert-team/index'));
// End About -> subpages

// Start Job Portal
const JobPortal = lazy(() => import('pages/job-portal/index'));
// End Job Portal

// Start Publication Portal
const Publication = lazy(() => import('pages/publication/index'));
// End Publication Portal

// Start Publication Portal
const FreeStudyMaterial = lazy(() => import('pages/free-study-material/index'));
// End Publication Portal

// Start Previous Years Papers
const PreviousYearPapers = lazy(() => import('pages/previous-year-papers/index'));
const ExamPapers = lazy(() => import('pages/previous-year-papers/exam-papers'));
// End Previous Years Papers

// Start Gallery -> subpages
const Gallery = lazy(() => import('pages/gallery/index'));
// End Gallery -> subpages

// Start Courses -> subpages
const Courses = lazy(() => import('pages/courses/index'));
const CourseList = lazy(() => import('pages/courses/course-list/index'));
const Course = lazy(() => import('pages/courses/course-details/index'));
// End Courses -> subpages

// Start My Courses
const MyCourses = lazy(() => import('pages/my-courses/course-list/index'));
const MyCourseContent = lazy(() => import('pages/my-courses/course-content/index'));
// End My Courses

// Start Login & Register
const Login = lazy(() => import('pages/auth/login/index'));
const Register = lazy(() => import('pages/auth/register/index'));
const Profile = lazy(() => import('pages/auth/profile/index'));
const ForgotPassword = lazy(() => import('pages/auth/forgot-password/index'));
const VerifyOtp = lazy(() => import('pages/auth/verify-otp/index'));
const ResetPassword = lazy(() => import('pages/auth/reset-password/index'));
// End Login & Register

// Start ContactUs
const ContactUs = lazy(() => import('pages/contact-us/index'));
// End ContactUs

// Start ContactUs
const Scholarship = lazy(() => import('pages/scholarship/index'));
// End ContactUs

// Start Admission
const Admission = lazy(() => import('pages/online-admission/index'));
// End Admission

// Start ViewTest
const ViewTest = lazy(() => import('pages/view-result/index'));
// End ViewTest

// Start AskQuestion
const AskQuestion = lazy(() => import('pages/ask-a-question/index'));
const Questions = lazy(() => import('pages/ask-a-question/question-page'));
// End AskQuestion

// Start OnlineTestSeries
const OnlineTest = lazy(() => import('pages/online-test-series/index'));
const TestScreen = lazy(() => import('pages/online-test-series/testScreen'));
const TestScreen2 = lazy(() => import('pages/online-test-series/testInstructions'));
const ResultScreen = lazy(() => import('pages/online-test-series/resultScreen'));
// End OnlineTestSeries

// Start DailyCurrentUpdates
const DailyCurrentUpdates = lazy(() => import('pages/daily-current-updates/index'));
const DailyCurrentPage = lazy(() => import('pages/daily-current-updates/update-pages/index'));
// End DailyCurrentUpdates

// Start Privacy
const Privacy = lazy(() => import('pages/privacy-policy/privacy'));
// End Privacy

// Start TermsUse
const TermsUse = lazy(() => import('pages/terms-of-use/termsUse'));
// End TermsUse

// Start TermsUse
const StudyMaterial = lazy(() => import('pages/study-material/studyMaterial'));
// End TermsUse

// Start RefundPolicy
const RefundPolicy = lazy(() => import('pages/refund-policy/refundPolicy'));
// End RefundPolicyimport ResultScreen from './pages/online-test-series/resultScreen';
// import RadialCharts from './pages/online-test-series/resultScreen';

const App = () => {
    return (
        <>
            <Suspense
                fallback={
                    <>
                        <Loader />
                    </>
                }
            >
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="about-us" element={<AboutUs />} />
                        <Route path="gallery" element={<Gallery />} />
                        <Route path="job-portal" element={<JobPortal />} />
                        <Route path="previous-year-papers" element={<PreviousYearPapers />} />
                        <Route path="previous-year-papers/:id/papers" element={<ExamPapers />} />
                        <Route path="courses" element={<Courses />} />
                        <Route path="courses/course-list/:id" element={<CourseList />} />
                        <Route path="courses/course-list/:id/:courseId" element={<Course />} />
                        <Route path="my-courses" element={<MyCourses />} />
                        <Route path="my-courses/:course/content" element={<MyCourseContent />} />
                        <Route path="publication" element={<Publication />} />
                        <Route path="ask-question" element={<AskQuestion />} />
                        <Route path="ask-question/:subName/:id" element={<Questions />} />
                        <Route path="ask-question/:subName/:id/question/:quesId/search/:searchparam" element={<Questions />} />
                        <Route path="login" element={<Login />} />
                        <Route path="Profile" element={<Profile />} />
                        <Route path="register" element={<Register />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="verify-otp" element={<VerifyOtp />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="admission" element={<Admission />} />
                        <Route path="online-test-series" element={<OnlineTest />} />
                        <Route path="free-study-material" element={<FreeStudyMaterial />} />
                        <Route path="scholarship-test" element={<Scholarship />} />
                        <Route path="scholarship-test/test/:testId" element={<TestScreen />} />
                        <Route path="free-study-material/:id/test/:testId" element={<TestScreen />} />
                        <Route path="result-analytics/:id" element={<ResultScreen />} />
                        <Route path="daily-current-updates" element={<DailyCurrentUpdates />} />
                        <Route path="daily-current-updates/:id" element={<DailyCurrentPage />} />
                        <Route path="privacy-policy" element={<Privacy />} />
                        <Route path="terms-of-use" element={<TermsUse />} />
                        <Route path="return-policy" element={<RefundPolicy />} />
                        <Route path="/view-result/:id" element={<ViewTest />} />
                        <Route path="free-study-material/:courseId" element={<StudyMaterial />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
};

export default App;
