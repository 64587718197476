import React from 'react';
import vedaLoader from './assets/images/cut png veda.png';
import 'assets/Loader/loader.css';

const Loader = () => {
    return (
        <>
            {/* <RotatingLines color="#4fa94d" width="100" visible={true} ariaLabel="falling-lines-loading" /> */}
            {/* <RotatingLines strokeColor="#4fa94d" strokeWidth="5" animationDuration="0.75" width="96" visible={true} /> */}
            <div
                className="loader"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    flexDirection: 'column'
                }}
            >
                <div className="img" style={{ position: 'relative' }}>
                    <div>
                        <img src={vedaLoader} alt="Loading...." height="65px" />
                    </div>
                    <div
                        className="lds-ellipsis"
                        style={{ position: 'absolute', top: '130%', left: '60%', transform: 'translate(-50% , -50%' }}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loader;
