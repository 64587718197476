import React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
// project imports
import logo from 'assets/images/logo.png';
import mobileLogo from 'assets/images/veda-Icon-512x512.png';
import vedaLogo from 'assets/images/veda-Icon-512x512.png';

function Index({ ...props }) {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    // extra css
    const style = {
        centerDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        msg1: {
            fontSize: isMobile ? '30px' : '30px',
            opacity: '0.3'
        }
    };
    return isMobile ? (
        <div className="image-container">
            <div style={style.centerDiv}>
                <div style={style.centerDiv}>
                    <img src={vedaLogo} alt="veda-Logo" height="300px" style={{ filter: 'opacity(0.3)' }} />
                </div>
                <div style={style.centerDiv}>
                    {props.msgno == 1 ? (
                        <p style={style.msg1}>Updates Comming Soon...</p>
                    ) : props.msgno == 2 ? (
                        <p style={style.msg1}>Loading...</p>
                    ) : (
                        <p style={style.msg1}>Content Not Found!!</p>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div className="image-container">
            <div style={style.centerDiv}>
                <div style={style.centerDiv}>
                    <img src={vedaLogo} alt="veda-Logo" height="300px" style={{ filter: 'opacity(0.3)' }} />
                </div>
                <div style={style.centerDiv}>
                    {props.msgno == 1 ? (
                        <p style={style.msg1}>Updates Comming Soon...</p>
                    ) : props.msgno == 2 ? (
                        <p style={style.msg1}>Loading...</p>
                    ) : (
                        <p style={style.msg1}>Not Found..</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Index;
