/* eslint-disable no-unused-vars */
import React from 'react';
import { Outlet, useMatch } from 'react-router-dom';

// bootstrap

// project imports
import Poster from 'common/poster/index';
import ScrollTop from 'common/scroll-top/index';
import ContactIcons from 'common/contact-icons/index';
import TopHeader from 'common/top-header/index';
import Header from 'common/header/index';
import Footer from 'common/footer/index';

function Index() {
    const matchFSM1 = useMatch('free-study-material/:courseId');
    const matchFSM2 = useMatch('free-study-material/:id/test/:testId');
    const matchFSM3 = useMatch('free-study-material');
    const matchRA = useMatch('result-analytics/:id');
    const matchST = useMatch('scholarship-test');
    return (
        <>
            <div>
                <div>
                    <TopHeader />
                </div>
                <div>
                    <Header />
                </div>
                <div>
                    <Outlet />
                </div>
                <div>
                    <Footer />
                </div>
            </div>

            {/* info and contact components */}
            {matchFSM1 || matchFSM2 || matchFSM3 || matchRA || matchST ? null : <Poster />}
            <ContactIcons />
            <ScrollTop />
        </>
    );
}

export default Index;
